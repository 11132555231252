import React from 'react';
import { styled } from '@mui/material/styles';
import { usePlaybookTheme } from 'playbook';
import { SubmitHandler } from 'react-hook-form';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-circle-outline.svg';

export const CONTAINER_MAX_WIDTH = '480px';

export type VerificationPayload = {
  recaptchaToken: string;
  lastName: string;
  gpid: string;
  email?: string;
};

export type VerificationResponse = {
  verified: boolean;
  emailAdded: boolean;
  error: boolean;
};

export type StepProps = {
  disableSubmit?: boolean;
  onSubmit: SubmitHandler<VerificationPayload>;
  mixpanelData?: Record<string, unknown>;
  onValuesChange?: (
    fields: Omit<VerificationPayload, 'recaptchaToken'>,
  ) => void;
};
export const FieldContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.baseUnit * 3,
}));

export const LabelText = styled('span')(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  lineHeight: `20px`,
  fontSize: 15,
  a: {
    color: theme.colors.gray[800],
  },
}));

const WhiteSheetContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: CONTAINER_MAX_WIDTH,
  backgroundColor: theme.colors.gray[1],
  position: 'relative',
  flex: 1,

  // Keep using old spacing for visual coherence
  padding: '60px',
  paddingTop: theme.spacing(2), // + Error spacer
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '12.5%',
    paddingRight: '12.5%',
  },
}));

const ErrorContainer = styled('div')(({ theme }) => ({
  padding: theme.baseUnit * 2,
  backgroundColor: theme.colors.error,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: CONTAINER_MAX_WIDTH,
  boxSizing: 'border-box',
}));
const ErrorText = styled(LabelText)(({ theme }) => ({
  color: theme.colors.gray[1],
}));
const ErrorIcon = styled(AlertIcon)(({ theme }) => ({
  color: theme.colors.gray[1],
  width: 20,
  height: 20,
}));
const EmptySpacer = styled('div')(({ theme }) => ({
  height: theme.spacing(6),
  backgroundColor: theme.colors.gray[1],
  width: '100%',
  maxWidth: CONTAINER_MAX_WIDTH,
  boxSizing: 'border-box',
}));
const FlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: CONTAINER_MAX_WIDTH,
  alignItems: 'center',
});

export const WhiteSheetWithErrorContainer: React.FC<
  React.PropsWithChildren<{
    style?: React.CSSProperties;
    error?: boolean;
    errorText?: string;
  }>
> = ({ error, children, errorText, ...rest }) => {
  const theme = usePlaybookTheme();
  const errorString =
    errorText ??
    `There was an error verifying your information. Please try again or
  contact support.`;

  return (
    <FlexContainer>
      {error && errorString ? (
        <ErrorContainer>
          <div style={{ marginRight: theme.baseUnit }}>
            <ErrorIcon />
          </div>
          <div>
            <ErrorText>{errorString}</ErrorText>
          </div>
        </ErrorContainer>
      ) : (
        <EmptySpacer />
      )}
      <WhiteSheetContainer {...rest}>{children}</WhiteSheetContainer>
    </FlexContainer>
  );
};

export const BlockTypography = styled('span')(({ theme }) => ({
  display: 'block',
  marginBottom: theme.baseUnit * 3,
}));

export const Title = styled(BlockTypography)(({ theme }) => ({
  ...theme.typography.textVariant.title.m,
}));

export const Subtitle = styled(BlockTypography)(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  b: {
    ...theme.typography.fontFamily.reader500,
  },
}));

export const FooterText = styled(BlockTypography)(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  marginBottom: theme.baseUnit * 2,

  a: {
    color: theme.colors.gray[800],
  },
  b: {
    ...theme.typography.fontFamily.reader700,
  },
}));
