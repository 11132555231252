import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import useMixpanel from 'hooks/useMixpanel';
import { FooterText, Subtitle, Title } from './shared';

const BlackButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.gray[800],
  width: '100%',
  color: theme.colors.gray[1],
  marginBottom: theme.baseUnit * 3,
  '&.Mui-disabled': {
    backgroundColor: theme.colors.gray[300],
  },
}));

export const BlackButton: React.FC<ButtonProps> = (props) => (
  <BlackButtonStyle {...props} />
);

interface FormLayoutProps extends React.PropsWithChildren {
  title: string;
  subtitle: React.ReactNode;
  btnText: string;
  onSubmit: () => void;
  submitDisabled?: boolean;
  mixpanelData?: Record<string, unknown>;
  afterSubmit?: React.ReactNode;
}
const FormLayout: React.FC<FormLayoutProps> = ({
  title,
  subtitle,
  btnText,
  onSubmit,
  submitDisabled,
  children,
  mixpanelData,
  afterSubmit,
}) => {
  const track = useMixpanel();

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
      {children}
      <BlackButton type="submit" onClick={onSubmit} disabled={submitDisabled}>
        {btnText}
      </BlackButton>
      {afterSubmit ?? null}
      <FooterText>
        <strong>Already verified?</strong>{' '}
        <a
          href="https://qrco.de/bdcsDz"
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            track('User Action', {
              event_name: 'download_app_link_click',
              event_type: 'screen interaction',
              ...mixpanelData,
            })
          }
        >
          Download the Exos app to get started
        </a>
        .
        <br />
        <strong>Need help?</strong> Contact{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://exosdigital.zendesk.com/hc/en-us/requests/new?ticket_form_id=13836393583764"
          onClick={() =>
            track('User Action', {
              event_name: 'contact_support',
              event_type: 'screen interaction',
              ...mixpanelData,
            })
          }
        >
          support@teamexos.com
        </a>
        .
      </FooterText>
    </form>
  );
};

export default FormLayout;
