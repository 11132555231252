import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { addBreadcrumb, captureException } from '@sentry/browser';
import useMixpanel from 'hooks/useMixpanel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@teamexos/fit-shared';
import { Button } from 'components/common/Button';
import NewTextField from 'components/common/fields/NewTextField';
import { Note, Error, Title, Subtitle, Content, TextLink } from './styles';
import Branding from './Branding';

interface StartForm {
  email: string;
}

const Start = () => {
  const {
    control,
    handleSubmit,
    setValue,

    formState: { isValid, errors, isSubmitting },
  } = useForm<StartForm>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = useParams();
  const { authorize, userCheck, forgotPasswordStart } = useAuth();
  const [loading, setLoading] = useState(false);

  const track = useMixpanel();
  const mixpanelData = useMemo(
    () => ({
      event_category: 'registration',
      event_location: 'registration_welcome',
    }),
    [],
  );
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Store the current location in local storage so we can redirect back to it after login
    if (!location.pathname.startsWith('/start')) {
      localStorage.setItem('authRedirect', location.pathname + location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email) {
      setValue('email', email);

      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onSubmit = async (data: StartForm) => {
    setLoading(true);

    track('User Action', {
      event_name: 'email_entered',
      event_type: 'screen interaction',
      ...mixpanelData,
    });

    try {
      const info = await userCheck(data.email, true);

      addBreadcrumb({
        category: 'userCheck',
        data: info,
        message: 'userCheck',
      });

      if (!info || !info?.registrationEnabled) {
        track('Screen View', {
          screen_name: 'registration_invalid_email',
        });

        setError(
          'This email address isn’t eligible. Please double-check your email address for accuracy. If the issue persists, contact your benefits provider to confirm eligibility.',
        );
        return;
      }

      if (info.active === false && info.registered === true) {
        setError('This account is inactive.');
        return;
      }

      const cognitoConfirm = info?.hasCognitoUser && !info.isEmailVerified;
      const unregistered = !info?.registered;

      if (cognitoConfirm || unregistered) {
        navigate('/complete-on-mobile');
        return;
      }

      if (info.resetRequired) {
        await forgotPasswordStart({
          connection: info.identity.connection,
          email: data.email,
        });

        navigate('password-expired', {
          state: {
            connection: info.identity.connection,
            provider: info.identity.provider,
            client: info.client,
            email: data.email,
          },
        });
        return;
      }

      setTimeout(async () => {
        if (info.isSSO) {
          await authorize({
            email: data.email,
            connection: info.identity.connection,
            provider: info.identity.provider,
          });
        } else {
          navigate('/login', {
            state: {
              provider: info.identity.provider,
              connection: info.identity.connection,
              client: info.client,
              email: data.email,
            },
          });
        }
      }, 10);
    } catch (e) {
      setError('Unexpected error. Please try again later.');
      captureException(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Branding>
      {error && <Error>{error}</Error>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Box>
            <Title>Enter your email</Title>
            <Subtitle>
              This is usually your work email or an eligible email you have
              provided us.
            </Subtitle>

            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Email is invalid',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <NewTextField
                  label="Email address"
                  inputProps={{
                    type: 'email',
                    autoComplete: 'email',
                    inputMode: 'email',
                  }}
                  value={value}
                  onChange={onChange}
                  isInvalid={!!errors.email}
                  helperText={errors.email?.message?.toString()}
                  mixpanelData={mixpanelData}
                />
              )}
            />
          </Box>

          <TextLink
            target="_blank"
            href="https://exosdigital.zendesk.com/hc/en-us/requests/new?ticket_form_id=13836393583764"
            rel="noreferrer"
          >
            Need help?
          </TextLink>
          <Note>
            Note: This app is only available to employees and eligible
            dependents of organizations that have partnered with Exos.
          </Note>

          <Button type="submit" disabled={!isValid || loading || isSubmitting}>
            Next
          </Button>
        </Content>
      </form>
    </Branding>
  );
};

export default Start;
