import React from 'react';
import {
  useAddSavedMutation,
  useGetOnDemandVideoBasicsQuery,
  useRemoveSavedMutation,
} from 'graphql/types';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import useMixpanel from 'hooks/useMixpanel';
import { matchPath, useLocation } from 'react-router-dom';

const DEFAULT_CONTAINER_SIZE = 26;
const DEFAULT_ICON_SIZE = 11;

type BookmarkButtonProps = {
  contentId: string;
  isSaved: boolean;
  containerSize?: number;
  iconSize?: number;
  mixpanelData?: Record<string, unknown>;
};

const Button = styled(IconButton)<{ isSaved: boolean; containerSize: number }>(
  ({ theme, containerSize, isSaved }) => ({
    backgroundColor: theme.colors.alias.mainBackground,
    width: containerSize,
    height: containerSize,
    opacity: isSaved ? 1 : 0.6,
    color: theme.colors.alias.iconPrimary,
    '&:hover, &:focus, &:focus-visible': {
      backgroundColor: 'transparent',
      color: theme.colors.alias.textContrast,
      border: `1px solid ${theme.colors.alias.mainBackground}`,
      opacity: 1,
    },
    '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
      backgroundColor: 'transparent',
    },
  }),
);

const BookmarkButton = ({
  contentId,
  isSaved,
  containerSize = DEFAULT_CONTAINER_SIZE,
  iconSize = DEFAULT_ICON_SIZE,
  mixpanelData = {},
}: BookmarkButtonProps) => {
  const track = useMixpanel();
  const [addSaved, { loading: adding }] = useAddSavedMutation();
  const [removeSaved, { loading: removing }] = useRemoveSavedMutation();
  const mutationInProgress = adding || removing;
  const location = useLocation();
  const isOnDemandDetails = !!matchPath('/ondemand/:id', location.pathname);

  const { data: contentData } = useGetOnDemandVideoBasicsQuery({
    fetchPolicy: 'cache-only',
    variables: {
      id: contentId,
    },
  });

  const trackTap = (eventName: string) => {
    // default event location, but it can be overridden by mixpanelData
    const eventLocation = isOnDemandDetails ? 'class_on_demand_detail' : 'home';

    track('User Action', {
      event_category: 'on demand',
      event_location: eventLocation,
      content_id: contentId,
      event_type: 'passive engagement',
      ...mixpanelData,
      event_name: eventName,
    });
  };

  const addToSaved = async () => {
    await addSaved({
      variables: {
        contentId,
      },
      optimisticResponse:
        contentData &&
        contentData?.content &&
        contentData.content.__typename === 'Video'
          ? {
              addSaved: {
                __typename: 'SavedPayload',
                content: {
                  ...contentData.content,
                  saved: true,
                },
                saved: true,
              },
            }
          : undefined,
    });
    trackTap('add_bookmark');
  };

  const removeFromSaved = async () => {
    removeSaved({
      variables: {
        contentId,
      },
    });
    trackTap('remove_bookmark');
  };

  const toggleSaved = async () => {
    if (!mutationInProgress) {
      if (!isSaved) {
        addToSaved();
      } else {
        removeFromSaved();
      }
    }
  };

  return (
    <Button
      isSaved={isSaved}
      containerSize={containerSize}
      onClick={(event) => {
        event.stopPropagation();
        toggleSaved();
      }}
      aria-label={isSaved ? 'Remove bookmark' : 'Add bookmark'}
    >
      <FontAwesomeIcon
        icon={isSaved ? faBookmarkSolid : faBookmark}
        width={iconSize}
        aria-hidden="true"
      />
    </Button>
  );
};

export default BookmarkButton;
