import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FullScreenPlayer from 'components/onDemand/FullScreenPlayer';
import Page404 from 'pages/404';

const FullScreen: React.FC = () => {
  // section info
  const { state } = useLocation();

  // video info
  const match = useParams();
  const hashedId: string | undefined = match.wistiaId;
  const contentId: string | undefined = match.id;

  if (contentId && hashedId) {
    return (
      <main>
        <FullScreenPlayer
          contentId={contentId}
          hashedId={hashedId}
          sectionId={state?.sectionId ?? ''}
        />
      </main>
    );
  }
  return <Page404 />;
};

export default FullScreen;
