import { styled } from '@mui/material/styles';
import { Checkbox, Link, TextField } from '@mui/material';

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import useMixpanel from 'hooks/useMixpanel';
import FormLayout from './FormLayout';
import {
  FieldContainer,
  FooterText,
  LabelText,
  StepProps,
  VerificationPayload,
} from './shared';

const CheckboxContainer = styled(FieldContainer)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});

const MypepsicoLink: React.FC<{ mixpanelData?: Record<string, unknown> }> = ({
  mixpanelData,
}) => {
  const track = useMixpanel();
  const onClick = () => {
    track('User Action', {
      event_name: 'link_click',
      event_type: 'screen interaction',
      event_label: 'mypepsico.com',
      ...mixpanelData,
    });
    window.open('https://mypepsico.com', '_blank')?.focus();
  };
  return (
    <span>
      Enter your last name and PepsiCo Global Personnel ID (GPID) as it appears
      in{' '}
      <Link onClick={onClick} style={{ cursor: 'pointer' }}>
        mypepsico.com
      </Link>
    </span>
  );
};

const ValidateCodeStep: React.FC<StepProps> = ({
  onSubmit,
  onValuesChange,
  disableSubmit,
  mixpanelData,
}) => {
  const track = useMixpanel();
  const [policyAccepted, setPolicyAccepted] = useState<boolean>();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid: isHookFormValid },
    watch,
  } = useForm<VerificationPayload & { emailConfirm?: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const isFormValid = useMemo(
    () => isHookFormValid && policyAccepted,
    [isHookFormValid, policyAccepted],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceTrack = useCallback(debounce(track, 600), []);

  useEffect(() => {
    const sub = watch((value, { name }) => {
      const event = {
        event_name: 'text_entered',
        event_type: 'screen interaction',
        ...mixpanelData,

        // Could be partially filled
        eligible_id: value.gpid,
      };

      if (onValuesChange) {
        onValuesChange({
          gpid: value.gpid ?? '',
          lastName: value.lastName ?? '',
          email: value.email,
        });
      }

      switch (String(name)) {
        case 'gpid':
          debounceTrack('User Action', {
            ...event,
            position: 0,
          });
          break;
        case 'lastName':
          debounceTrack('User Action', {
            ...event,
            position: 2,
          });
          break;
        default:
          break;
      }
    });

    return () => sub.unsubscribe();
  }, [watch, onValuesChange, debounceTrack, mixpanelData]);

  return (
    <FormLayout
      title="Verify your eligibility to access the Exos app for free."
      subtitle={<MypepsicoLink mixpanelData={mixpanelData} />}
      btnText="Verify"
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={!isFormValid || disableSubmit}
      mixpanelData={{
        event_location: 'verify',
        ...mixpanelData,
      }}
      afterSubmit={
        <FooterText>
          <span>
            <strong>Verifying a family member?</strong> Please note that
            eligible spouses and dependents have a unique GPID. Typically this
            is the employee&apos;s GPID with an S at the end for a spouse, or a
            C plus a number for a dependent.
          </span>
        </FooterText>
      }
    >
      <FieldContainer>
        <TextField
          key="gpid"
          defaultValue=""
          error={!!errors.gpid}
          helperText={errors?.gpid?.message}
          placeholder="Global Personnel ID (GPID)* "
          fullWidth
          variant="standard"
          {...register('gpid', { required: true })}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          key="lastName"
          defaultValue=""
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
          placeholder="Last name* "
          fullWidth
          variant="standard"
          {...register('lastName', { required: true })}
        />
      </FieldContainer>
      <CheckboxContainer>
        {/* Adjust the checkbox */}
        <div style={{ marginLeft: -10, marginTop: -10 }}>
          <Checkbox
            id="ack"
            color="primary"
            onChange={(e) => {
              const value = e.target.checked;
              setPolicyAccepted(value);

              if (value === true) {
                track('User Action', {
                  event_name: 'acknowledgement_check',
                  event_type: 'screen interaction',
                  ...mixpanelData,
                });
              }
            }}
          />
        </div>
        <label htmlFor="ack">
          <LabelText>
            I acknowledge the Exos{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.teamexos.com/privacy-policy-security-notice/"
              onClick={() =>
                track('User Action', {
                  event_name: 'privacy_policy_link_click',
                  event_type: 'screen interaction',
                  ...mixpanelData,
                })
              }
            >
              Privacy Policy
            </a>{' '}
            and agree to the transfer and processing of my data to the United
            States.
          </LabelText>
        </label>
      </CheckboxContainer>
    </FormLayout>
  );
};

export default ValidateCodeStep;
