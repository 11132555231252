import { styled } from '@mui/material/styles';

import React, { useCallback, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler } from 'react-hook-form';
import exosLogo from 'assets/logo.png';
import exosLogo2x from 'assets/logo@2x.png';
import healthylivingLogo from 'assets/healthyliving-logo.png';
import xIcon from 'assets/icons/x-icon.png';
import useMixpanel from 'hooks/useMixpanel';
import { apiFetch } from 'utils/fetch';
import logger from 'utils/logger';
import { captureException } from '@sentry/browser';
import AddEmailStep from './AddEmailStep';
import {
  VerificationPayload,
  VerificationResponse,
  WhiteSheetWithErrorContainer,
} from './shared';
import SuccessStep from './SuccessStep';
import ValidateCodeStep from './ValidateCodeStep';

const UI_STEP = {
  VerifyEmail: 'verify-email',
  AddEmail: 'add-email',
  Success: 'success',
} as const;

const ENDPOINT = `${process.env.REACT_APP_PRINCE_GQL_URL}/eligibility-verification`;

const LogoContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.baseUnit * 6,
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  width: 285,

  // Edge case
  '@media (max-width: 280px)': {
    maxWidth: '100%',
    flexDirection: 'column',
    height: 120,
  },
}));
const Image = styled('img')({
  maxWidth: '100%',
});

// arbitrary name that needs to match with what we check on the backend
const RECAPTCHA_ACTION = 'eligibilityVerification';

const validateOrAddEmail = async (
  payload: VerificationPayload,
): Promise<VerificationResponse> => {
  try {
    const res = await apiFetch({
      url: ENDPOINT,
      method: 'POST',
      body: {
        ...payload,
      },
      unauthenticated: true,
    });

    const json: VerificationResponse = await res.json();

    if (!res.ok) {
      throw new Error('Error with GPID verification');
    }

    logger.debug('User check response', json);
    return json as VerificationResponse;
  } catch (e) {
    captureException(e);
    throw e;
  } finally {
    logger.debug('Sent', payload);
  }
};

const FlexContainer = styled('div')(({ theme }) => ({
  paddingTop: '110px',
  paddingBottom: '110px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '60px',
    paddingBottom: '60px',
  },
}));

const GPIDValidation: React.FC = () => {
  const track = useMixpanel();

  // Higher level state controlling what screen is shown
  const [uiState, setState] = useState<VerificationResponse>({
    verified: false,
    emailAdded: false,
    error: false,
  });

  // Saved data between screens
  const [userData, setUserData] = useState<
    Omit<VerificationPayload, 'recaptchaToken'>
  >({
    lastName: '',
    gpid: '',
    email: '',
  });

  // Step that the user is in
  const uiStep: (typeof UI_STEP)[keyof typeof UI_STEP] = useMemo(() => {
    if (uiState.emailAdded === true && uiState.verified === true) {
      return UI_STEP.Success;
    }

    if (uiState.emailAdded === false && uiState.verified === true) {
      return UI_STEP.AddEmail;
    }

    // Default: uiState.emailAdded === false && uiState.verified === false
    return UI_STEP.VerifyEmail;
  }, [uiState]);

  //
  const [loading, setLoading] = useState<boolean>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const mixpanelData = useMemo(
    () => ({
      event_category: 'eligibility flow',
      eligible_id: userData.gpid,
    }),
    [userData],
  );

  const onValidateCode: SubmitHandler<VerificationPayload> = useCallback(
    async (formData) => {
      track('User Action', {
        event_location: 'verify',
        event_name: 'verify_button_click',
        event_type: 'screen interaction',
        ...mixpanelData,
      });

      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha(RECAPTCHA_ACTION)
        : '';

      const payload: VerificationPayload = {
        lastName: formData.lastName.trim(),
        gpid: formData.gpid.trim(),
        recaptchaToken,
      };

      // Save for use in the next screen
      setUserData({
        lastName: payload.lastName,
        gpid: payload.gpid,
      });

      setLoading(true);
      validateOrAddEmail(payload)
        .catch((e) => {
          track('User Action', {
            event_location: 'verify',
            event_name: 'error',
            event_type: 'screen interaction',
            ...mixpanelData,
          });
          setState((state) => ({ ...state, error: true }));
          captureException(e);
        })
        .then((response) => {
          setState((state) => ({ ...state, ...response }));
        })
        .finally(() => setLoading(false));
    },
    [setLoading, setUserData, executeRecaptcha, track, mixpanelData],
  );

  const onAddEmail: SubmitHandler<VerificationPayload> = useCallback(
    async (formData) => {
      track('User Action', {
        event_location: 'email_address',
        event_name: 'submit_button_click',
        event_type: 'screen interaction',
        ...mixpanelData,
      });

      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha(RECAPTCHA_ACTION)
        : '';

      const payload: VerificationPayload = {
        ...userData, // Use saved values
        email: formData.email?.trim(),
        recaptchaToken,
      };

      // Update saved payload
      setUserData((state) => ({ ...state, email: payload.email }));

      setLoading(true);
      validateOrAddEmail(payload)
        .catch((e) => {
          setState((state) => ({ ...state, error: true }));
          captureException(e);
        })
        .then((response) => {
          setState((state) => ({
            ...state,
            ...response,
          }));
        })
        .finally(() => setLoading(false));
    },
    [setLoading, executeRecaptcha, userData, mixpanelData, track],
  );

  // Save the GPID as it's being typed for mixpanel tracking
  const onValidateValuesChange = useCallback(
    (fields: Omit<VerificationPayload, 'recaptchaToken'>) =>
      setUserData((state) => ({ ...state, gpid: fields.gpid })),
    [setUserData],
  );

  return (
    <FlexContainer>
      <header>
        <LogoContainer>
          <Image
            src={exosLogo}
            srcSet={`${exosLogo} 1x,${exosLogo2x} 2x`}
            alt="Exos"
            style={{ width: 92 }}
          />
          <Image src={xIcon} alt="X" style={{ width: 20 }} />
          <Image
            src={healthylivingLogo}
            style={{ width: 135 }}
            alt="Healthy Living"
          />
        </LogoContainer>
      </header>

      <main>
        {uiStep === UI_STEP.VerifyEmail ? (
          <WhiteSheetWithErrorContainer error={uiState.error}>
            <ValidateCodeStep
              disableSubmit={loading}
              onSubmit={onValidateCode}
              onValuesChange={onValidateValuesChange}
              mixpanelData={{ ...mixpanelData, event_location: 'verify' }}
            />
          </WhiteSheetWithErrorContainer>
        ) : null}

        {uiStep === UI_STEP.AddEmail ? (
          <WhiteSheetWithErrorContainer
            error={uiState.error}
            errorText="There was an error with your email address. Please try again or contact support."
          >
            <AddEmailStep
              disableSubmit={loading}
              onSubmit={onAddEmail}
              mixpanelData={{
                ...mixpanelData,
                event_location: 'email_address',
              }}
            />
          </WhiteSheetWithErrorContainer>
        ) : null}

        {uiStep === UI_STEP.Success ? (
          <SuccessStep
            email={userData.email}
            gpid={userData.gpid}
            mixpanelData={mixpanelData}
          />
        ) : null}
      </main>
    </FlexContainer>
  );
};

export default GPIDValidation;
