import { styled } from '@mui/material/styles';

const SectionTitle = styled('span')(({ theme }) => ({
  ...theme.typography.textVariant.title.m,
  display: 'block',
  color: theme.colors.alias.textPrimary,
  marginBottom: theme.baseUnit * 3,
}));

export default SectionTitle;
