import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import useParamsSetFilters from 'hooks/useParamsSetFilters';
import List from './onDemand/List';
import ContentGenres from './onDemand/ContentGenres';
import SavedSection from './onDemand/SavedSection';
import SectionTitle from './common/SectionTitle';

const Classes = (): ReactElement => {
  const { loading } = useParamsSetFilters();

  return (
    <div>
      {!loading ? (
        <>
          <SavedSection />
          <SectionTitle role="heading" aria-level={2}>
            On Demand Classes
          </SectionTitle>
          <ContentGenres key="on-demand-list-cg" />
          <List key="on-demand-list" />
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default Classes;
