import React, { useEffect, useState } from 'react';
import {
  OnDemandVideoPreviewFragment,
  useGetUserSavedVideosQuery,
} from 'graphql/types';
import { useNavigate } from 'react-router-dom';
import Container from 'components/Container';
import ClassList from 'components/ClassList';
import SectionTitle from 'components/common/SectionTitle';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { Button } from 'components/common/Button';
import { selfSavedContentQueryUpdater } from 'utils/queryUpdaters';
import useMixpanel from 'hooks/useMixpanel';
import { usePlaybookTheme } from 'playbook';

const NoSavedClassesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.colors.alias.mainBackground,
  flex: 1,
  minHeight: theme.baseUnit * 50,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  paddingTop: theme.baseUnit * 3,
  marginTop: theme.baseUnit * 4,
}));

const NoSavedTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.m,
  color: theme.colors.alias.textPrimary,
  marginBottom: theme.baseUnit * 2,
  marginTop: theme.baseUnit * 3,
}));

const NoSavedSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.body.m,
  color: theme.colors.alias.textDisabled,
  marginBottom: theme.baseUnit * 5,
}));

const StickyHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  zIndex: 2,
  backgroundColor: theme.colors.alias.mainBackground,
  top: 0,
  marginBottom: theme.baseUnit * 4,
  paddingBottom: theme.baseUnit,
  paddingTop: theme.baseUnit * 3,
}));

const NoSavedClasses = () => {
  const theme = usePlaybookTheme();
  const track = useMixpanel();
  const navigate = useNavigate();
  const onClick = () => {
    track('User Action', {
      event_category: 'on demand',
      event_location: 'saved_classes',
      event_name: 'start_saving',
      event_subcategory: 'saved',
      event_type: 'passive engagement',
    });
    navigate('/');
  };

  return (
    <NoSavedClassesWrapper>
      <FontAwesomeIcon
        icon={faBookmark}
        style={{ height: theme.baseUnit * 6 }}
        color={theme.colors.alias.iconPrimary}
      />
      <NoSavedTitle role="heading" aria-level={2}>
        No saved classes yet
      </NoSavedTitle>
      <NoSavedSubTitle>Start saving classes to your collection</NoSavedSubTitle>
      <Button onClick={onClick}>Browse Classes</Button>
    </NoSavedClassesWrapper>
  );
};

const SavedClasses = () => {
  const { data, loading, fetchMore } = useGetUserSavedVideosQuery();

  const classes = (data?.self?.savedContent.nodes ??
    []) as OnDemandVideoPreviewFragment[];

  const pageInfo = data?.self?.savedContent.pageInfo;
  const [classIdToShow, setClassIdToShow] = useState('');
  const navigate = useNavigate();
  const track = useMixpanel();

  useEffect(() => {
    // make sure the page is scrolled to the top when the component mounts
    window.scrollTo(0, 0);

    track('Screen View', {
      page_name: 'saved_classes',
    });
  }, [track]);

  const onModalOpen = (classId: string) => {
    setClassIdToShow(classId);
    navigate(`/saved/${classId}`);
  };

  const onModalClose = () => {
    setClassIdToShow('');
    navigate('/saved');
  };

  const handleOnEndReached = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          connectionInput: {
            after: pageInfo?.endCursor,
          },
        },
        updateQuery: selfSavedContentQueryUpdater,
      });
    }
  };

  return (
    <main>
      <div style={{ flex: 1, display: 'flex' }}>
        <StyledContainer>
          <StickyHeader>
            <SectionTitle role="heading" aria-level={2}>
              Saved Classes
            </SectionTitle>
          </StickyHeader>

          {!loading && classes.length === 0 && <NoSavedClasses />}

          {(loading || classes.length > 0) && (
            <ClassList
              classes={classes}
              loading={loading}
              handleOnEndReached={handleOnEndReached}
              pageInfo={pageInfo}
              handleClick={(item) => onModalOpen(item.id)}
              classIdToShow={classIdToShow}
              onModalClose={onModalClose}
              mixpanelData={{
                event_location: 'saved_classes',
                event_subcategory: 'saved',
              }}
            />
          )}
        </StyledContainer>
      </div>
    </main>
  );
};

export default SavedClasses;
