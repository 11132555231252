import { TextField } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { isValidEmail } from 'utils/helpers';
import useMixpanel from 'hooks/useMixpanel';
import FormLayout from './FormLayout';
import { FieldContainer, StepProps, VerificationPayload } from './shared';

const AddEmailStep: React.FC<StepProps> = ({
  onSubmit,
  disableSubmit,
  mixpanelData,
}) => {
  const track = useMixpanel();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid: isFormValid },
    watch,
  } = useForm<VerificationPayload & { emailConfirm?: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceTrack = useCallback(debounce(track, 600), []);

  useEffect(() => {
    const sub = watch((_, { name }) => {
      const event = {
        event_name: 'text_entered',
        event_type: 'screen interaction',
        ...mixpanelData,
      };

      switch (String(name)) {
        case 'email':
          debounceTrack('User Action', {
            ...event,
            position: 0,
          });
          break;
        default:
          break;
      }
    });

    return () => sub.unsubscribe();
  }, [debounceTrack, mixpanelData, watch]);

  const handleEmailValidation = (email?: string) => isValidEmail(email ?? '');
  return (
    <FormLayout
      title="Enter Your Email Address"
      subtitle="Choose a unique personal or professional email address to create your account."
      btnText="Submit"
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={!isFormValid || disableSubmit}
      mixpanelData={{
        event_location: 'email_address',
      }}
    >
      <FieldContainer>
        <TextField
          key="email"
          defaultValue=""
          error={!!errors.email}
          type="email"
          helperText={errors?.email?.message}
          placeholder="Enter email address* "
          fullWidth
          {...register('email', {
            required: true,
            validate: handleEmailValidation,
          })}
          variant="standard"
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          key="emailConfirm"
          defaultValue=""
          error={!!errors.emailConfirm}
          type="email"
          helperText={errors?.emailConfirm?.message}
          placeholder="Confirm email address* "
          fullWidth
          {...register('emailConfirm', {
            required: true,
            validate: (value) => {
              if (value === getValues().email) {
                return true;
              }
              return 'The emails do not match';
            },
          })}
          variant="standard"
        />
      </FieldContainer>
    </FormLayout>
  );
};

export default AddEmailStep;
