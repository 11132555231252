import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, IconButton, Modal, Typography } from '@mui/material';
import {
  ClassFiltersT,
  useClassFilterContext,
  Section,
} from 'contexts/classFilterContext';
import { useParams } from 'react-router-dom';
import useMixpanel from 'hooks/useMixpanel';
import { MPActionEvent } from 'types/mixpanel';
import { Button } from 'components/common/Button';
import { usePlaybookTheme } from 'playbook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import FilterSection from './FilterSection';

const TextButton = styled('button')(({ theme }) => ({
  ...theme.typography.textVariant.button.s,
  color: theme.colors.alias.textPrimary,
  background: 'none',
  border: 'none',
  textTransform: 'uppercase',
  cursor: 'pointer',
}));

interface FiltersProps {
  open: boolean;
  onClose: () => void;
  valueKey?: Section;
}

export const durations = ['5 min', '15 min', '30 min', '45+ min'];

const getOpenedSections = (value: ClassFiltersT) => {
  const filters = Object.keys(value) as (keyof ClassFiltersT)[];

  // Open only sections that have filters selected
  return filters.reduce(
    (acc, key) => {
      const length = value[key]?.length ?? 0;
      acc[key] = length > 0;
      return acc;
    },
    {} as Record<keyof ClassFiltersT, boolean>,
  );
};

const Filters = ({ open, onClose, valueKey = 'onDemand' }: FiltersProps) => {
  const track = useMixpanel();
  const theme = usePlaybookTheme();

  const slugFromParams = useParams();

  const { state, setFilters } = useClassFilterContext();
  const brands = useMemo(
    () => state?.availableOnDemand?.brands ?? [],
    [state?.availableOnDemand?.brands],
  );
  const levels = useMemo(
    () => state?.availableOnDemand?.levels ?? [],
    [state?.availableOnDemand?.levels],
  );
  const equipment = useMemo(
    () => state?.availableOnDemand?.equipment ?? [],
    [state?.availableOnDemand?.equipment],
  );

  /**
   * LOCAL STATE
   */
  const getDefaultState = useCallback(
    () => ({
      genres: [] as string[],
      brands: [] as string[],
      durations: [] as string[],
      equipment: [] as string[],
      levels: [] as string[],
    }),
    [],
  );

  const [tags, setLocalFilters] = useState({
    ...getDefaultState(),
    ...state?.[valueKey],
  });

  const clearFilters = useCallback(() => {
    const eventLocation = tags.genres.length ? tags.genres[0] : 'home';
    track('User Action', {
      event_category: 'on demand',
      event_location: eventLocation,
      event_type: 'screen interaction',
      event_name: 'clear_filter',
    });
    // Keep genre state when clearing filters
    setLocalFilters({ ...getDefaultState(), genres: tags.genres });
  }, [getDefaultState, tags.genres, track]);

  const [openedSections, setOpenedSections] = useState(getOpenedSections(tags));

  const toggleSection = useCallback(
    (section: keyof typeof openedSections) => {
      const newValue = !openedSections[section];
      const eventLocation = tags.genres.length ? tags.genres[0] : 'all';

      track('User Action', {
        event_category: 'on demand',
        event_subcategory: section,
        event_location: eventLocation,
        event_type: 'screen interaction',
        event_name: newValue ? 'expand_filter' : 'collapse_filter',
      });
      setOpenedSections({
        ...openedSections,
        [section]: newValue,
      });
    },
    [openedSections, tags.genres, track],
  );

  const toggleItem = (filterName: keyof ClassFiltersT, val: string) => {
    const filter = tags[filterName];
    if (!filter) {
      return;
    }

    const index = filter.indexOf(val);
    if (index === -1) {
      filter.push(val);
    } else {
      filter.splice(index, 1);
    }

    setLocalFilters({
      ...tags,
      [filterName]: filter,
    });
  };

  /**
   * ON CHANGE FILTERS STATE, UPDATE LOCAL FILTERS
   */
  useEffect(() => {
    // On change filters state, reflect it here
    const newTags = {
      genres: state?.[valueKey].genres ?? [],
      brands: state?.[valueKey].brands ?? [],
      durations: state?.[valueKey].durations ?? [],
      equipment: state?.[valueKey].equipment ?? [],
      levels: state?.[valueKey].levels ?? [],
    };
    setLocalFilters(newTags);

    const newOpenedSections = getOpenedSections(newTags);
    setOpenedSections(newOpenedSections);
  }, [state, valueKey]);

  const showBrandsSection = brands.length && !slugFromParams.collectionSlug;
  const brandsSection = (
    <FilterSection
      title="Brand"
      isOpened={openedSections.brands}
      section="brands"
      onToggleExpand={toggleSection}
      items={brands}
      activeItems={tags.brands}
      onToggleItem={(item) => {
        toggleItem('brands', item);
      }}
    />
  );

  let filtersCount = Object.values(tags).reduce(
    (acc, filter) => acc + (filter?.length ?? 0),
    0,
  );

  // not not count genres on filters count
  filtersCount -= tags.genres.length;

  const equipmentSection = (
    <FilterSection
      title="Equipment"
      isOpened={openedSections.equipment}
      section="equipment"
      onToggleExpand={toggleSection}
      items={equipment}
      activeItems={tags.equipment}
      onToggleItem={(item) => {
        toggleItem('equipment', item);
      }}
    />
  );

  const levelsSection = (
    <FilterSection
      title="Level"
      isOpened={openedSections.levels}
      section="levels"
      onToggleExpand={toggleSection}
      items={levels}
      activeItems={tags.levels}
      onToggleItem={(item) => {
        toggleItem('levels', item);
      }}
    />
  );
  const durationsSection = (
    <FilterSection
      title="Duration"
      isOpened={openedSections.durations}
      section="durations"
      onToggleExpand={toggleSection}
      items={durations}
      activeItems={tags.durations}
      onToggleItem={(item) => {
        toggleItem('durations', item);
      }}
    />
  );

  const sendTracking = useCallback(
    (
      filters: {
        brands: string[];
        genres: string[];
        levels: string[];
        durations: string[];
        equipment: string[];
      } & ClassFiltersT,
    ) => {
      const baseData: MPActionEvent = {
        event_name: 'update_filter',
        event_type: 'screen interaction',
        event_category: 'on demand',
        event_location: 'classes_on_demand_filter',
      };

      const brandsData =
        showBrandsSection && filters.brands.length
          ? { brands: filters.brands }
          : null;

      const levelsData = filters.levels.length && {
        levels: filters.levels,
      };

      const durationData = filters.durations.length && {
        durations: filters.durations,
      };

      const equipmentData = filters.equipment.length && {
        equipment: filters.equipment,
      };

      track('User Action', {
        ...baseData,
        ...(brandsData && brandsData),
        ...(levelsData && levelsData),
        ...(durationData && durationData),
        ...(equipmentData && equipmentData),
      });
    },
    [track, showBrandsSection],
  );

  const updateFilters = useCallback(() => {
    sendTracking(tags);
    setFilters(valueKey, tags);
  }, [setFilters, tags, valueKey, sendTracking]);

  return (
    <ModalStyled
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper>
          <ModalHeader>
            <ModalTitle variant="h1">Filters</ModalTitle>
            <IconButton onClick={onClose} style={{ padding: '0 8px' }}>
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 24, color: 'black' }}
              />
            </IconButton>
          </ModalHeader>

          <ScrollableContainer>
            {equipment.length ? equipmentSection : null}
            {levels.length ? levelsSection : null}
            {durations.length ? durationsSection : null}
            {showBrandsSection ? brandsSection : null}
          </ScrollableContainer>

          <SubmitContainer>
            <TextButton onClick={clearFilters}>Clear All</TextButton>
            <Button
              style={{
                ...theme.typography.textVariant.button.l,
              }}
              onClick={() => {
                updateFilters();
                onClose();
              }}
            >
              Apply Filters {filtersCount ? `(${filtersCount})` : ''}
            </Button>
          </SubmitContainer>
        </Paper>
      </Fade>
    </ModalStyled>
  );
};

export default Filters;

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Paper = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  paddingTop: theme.baseUnit,
  paddingBottom: theme.baseUnit * 3,
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
}));

const ScrollableContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  paddingLeft: theme.baseUnit * 3,
  paddingRight: theme.baseUnit * 3,
  width: '420px',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.alias.textPrimary,
  ...theme.typography.textVariant.title.m,
  paddingBottom: theme.baseUnit,
  paddingTop: theme.baseUnit,
}));

const ModalHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.baseUnit * 3,
  paddingRight: theme.baseUnit * 3,
  marginBottom: theme.baseUnit * 2,
}));

const SubmitContainer = styled('div')(({ theme }) => ({
  padding: theme.baseUnit * 3,
  paddingTop: theme.baseUnit * 2,
  paddingBottom: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
}));
