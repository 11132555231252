import packageInfo from '../../package.json';

export interface AuthenticatedFetchOptions {
  method?: string;
  url?: string;
  options?: Record<string, unknown>;
  body?: any;
  headers?: Record<string, unknown>;
  unauthenticated?: false;
}
export interface UnauthenticatedFetchOptions {
  method?: string;
  url?: string;
  options?: Record<string, unknown>;
  body?: any;
  headers?: Record<string, unknown>;
  unauthenticated: true;
}

async function getHeaders(token?: string, includeAuthorization = true) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'app-version': packageInfo.version,
  });
  if (includeAuthorization && token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
}

interface ApiFetch {
  (options: string, token: string): Promise<Response>;
  (options: AuthenticatedFetchOptions, token: string): Promise<Response>;
  (options: UnauthenticatedFetchOptions, token?: string): Promise<Response>;
}

export const apiFetch: ApiFetch = async (cfg, token) => {
  const config = typeof cfg === 'string' ? { url: cfg } : { ...cfg };

  const {
    method = 'GET',
    url = '/',
    options = {},
    body,
    unauthenticated,
  } = config;
  const headers = await getHeaders(token, !unauthenticated);
  return fetch(url, {
    method,
    headers,
    body: (typeof body !== 'string' && JSON.stringify(body)) || body,
    ...options,
  });
};
