import { usePlaybookTheme } from 'playbook';

import { CloseOutline } from 'react-ionicons';

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';
import useMixpanel from 'hooks/useMixpanel';

/**
 * Displays a hideable banner
 * @param {string} text Text displayed to the user
 * @param {string} id A unique ID to identify the banner
 */
const Banner = ({ text, id }: { text: string; id: string }) => {
  const { colors } = usePlaybookTheme();
  const track = useMixpanel();

  const [wasDismissed, setWasDismissed] = useState(true);
  useEffect(() => {
    // get serialized array of banners user has acknowledged
    const storageString = localStorage.getItem('dismissed_banners');
    if (storageString !== null) {
      const dismissedBanners = JSON.parse(storageString);

      if (!dismissedBanners.includes(id)) {
        setWasDismissed(false);
      }
    } else {
      setWasDismissed(false);
    }
  }, [id]);

  const onCloseClick = () => {
    track('User Action', {
      event_name: 'close_click',
      event_type: 'screen interaction',
      event_category: 'gameplan',
      event_location: 'home',
      event_subcategory: 'web banner',
    });
    setWasDismissed(true);
    // get serialized array of banners user has acknowledged
    const storageString = localStorage.getItem('dismissed_banners');
    // If no local storage entry exists, create one
    if (!storageString) {
      localStorage.setItem('dismissed_banners', JSON.stringify([id]));
      return;
    }

    const dismissedBanners = JSON.parse(storageString);
    // If the current banner is not in the list, add it and update local storage
    if (!dismissedBanners.includes(id)) {
      dismissedBanners.push(id);
      localStorage.setItem(
        'dismissed_banners',
        JSON.stringify(dismissedBanners),
      );
    }
  };

  if (wasDismissed) {
    return null;
  }

  return (
    <Container>
      <BannerText>{text}</BannerText>
      <CloseButton aria-label="Dismiss banner" onClick={onCloseClick}>
        <CloseOutline width="32px" height="32px" color={colors.gray[50]} />
      </CloseButton>
    </Container>
  );
};

export default Banner;

const Container = styled('div')(({ theme }) => ({
  height: theme.baseUnit * 6,
  backgroundColor: theme.colors.gray[800],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const BannerText = styled('p')(({ theme }) => ({
  fontSize: 14,
  ...theme.typography.fontFamily.sharp600,
  color: theme.colors.gray['50'],
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.baseUnit * 3,
  '& span': {
    display: 'flex',
  },
}));
