import React from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  GridWrap,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { styled } from '@mui/material/styles';

import { OnDemandVideoPreviewFragment } from 'graphql/types';
import ClassTile from 'components/ClassTile';
import { uniqueId } from 'lodash';
import ClassModal from './onDemand/ClassModal';

const EmptyText = styled(Typography)(({ theme }) => ({
  marginTop: theme.baseUnit * 4,
  paddingLeft: '28px',
}));

const LoaderRow = styled(Box)(({ theme }) => ({
  padding: theme.baseUnit * 3,
  margin: theme.baseUnit * 2,
  flexBasis: '100%',
  textAlign: 'center',
}));

type RenderArgTypes = {
  item: OnDemandVideoPreviewFragment;
  index: number;
};

const TileSkeletons = () =>
  new Array(12).fill(1).map(() => (
    <Grid item xs={4} lg={3} key={uniqueId()}>
      <ClassTile skeleton />
    </Grid>
  ));

type ListProps = {
  handleOnEndReached?: () => void;
  pageInfo: any;
  loading: boolean;
  handleClick: (item: OnDemandVideoPreviewFragment) => void;
  classes: OnDemandVideoPreviewFragment[];
  classIdToShow?: string;
  onModalClose?: () => void;
  mixpanelData?: Record<string, unknown>;
  wrap?: GridWrap; // allow having a horizontal scroll
};

const ClassList = ({
  handleOnEndReached,
  pageInfo,
  loading,
  handleClick,
  classes,
  classIdToShow,
  onModalClose = () => {},
  mixpanelData,
  wrap = 'wrap',
}: ListProps) => {
  const muiTheme = useTheme();
  const isMdDown = useMediaQuery(muiTheme.breakpoints.down('lg'));

  const shouldLoadMore = pageInfo?.hasNextPage && !!handleOnEndReached;
  const loadMore = handleOnEndReached || (() => {});

  const renderItem = ({ item, index }: RenderArgTypes) => (
    <Grid
      flex={1}
      item
      xs={4}
      lg={3}
      key={`vid-${item.id}`}
      data-key={`vid-${item.id}`}
    >
      <ClassTile
        contentId={item.id}
        onClick={() => handleClick(item)}
        title={item.title ?? ''}
        image={item.heroPhoto}
        mixpanelData={{
          ...mixpanelData,
          position: index,
        }}
        subtitleHTML={[
          <span key="min">{Math.floor(Number(item.time))} min · </span>,
          <span key="meta" style={{ textTransform: 'capitalize' }}>
            {[
              `${item?.contentGenre?.name ?? ''}`,
              `${item?.contentLevel?.name ?? ''}`,
            ]
              .filter(Boolean)
              .join(' · ')}
          </span>,
        ]}
        saved={item.saved}
      />
    </Grid>
  );

  return (
    <>
      <Grid
        wrap={wrap}
        container
        spacing={isMdDown ? 2 : 3}
        component={InfiniteScroll}
        loadMore={loadMore}
        hasMore={shouldLoadMore}
        initialLoad={false}
        loader={
          <LoaderRow key="progress">
            <CircularProgress color="inherit" aria-label="Loading" />
          </LoaderRow>
        }
        display="flex"
      >
        {loading ? (
          <TileSkeletons />
        ) : classes.length ? (
          classes.map((item, index) => renderItem({ item, index }))
        ) : (
          <EmptyText>Nothing found.</EmptyText>
        )}
      </Grid>
      {classIdToShow && classIdToShow !== '' && (
        <ClassModal open onClose={onModalClose} id={classIdToShow} />
      )}
    </>
  );
};

export default ClassList;
