// Deeplinks That Are Supported in the Fit Mobile App
export const supportedDeepLinks = {
  '/activity': 1,
  '/addactivity': 1,
  '/challenges': 1,
  '/challenges/badges': 1,
  '/classes/inperson': 1,
  '/coaching': 1,
  '/connections': 1,
  '/dietitians': 1,
  '/discover/classes': 1,
  '/discover/foryou': 1,
  '/discover/practices': 1,
  '/discover/programs': 1,
  '/familysharing': 1,
  '/history': 1,
  '/history/challenges': 1,
  '/notifications': 1,
  '/permissions': 1,
  '/practices': 1,
  '/preferences': 1,
  '/profile': 1,
  '/programs': 1,
  '/settings': 1,
  '/schedule': 1,
  '/schedule/virtual': 1,
  '/schedule/inperson': 1,
  '/saved': 1,
  '/support': 1,
  // Deeplinks With Params
  '/addactivity/*': 1,
  '/chat/*': 1,
  '/challenge/*': 1,
  '/class/*': 1,
  '/coach/*': 1,
  '/coachsessions/*': 1,
  '/discover/classes?*': 1,
  '/practice/*': 1,
  '/program/*': 1,
  '/session/*': 1,
};
