import { styled } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/pro-regular-svg-icons';

import downloadQR from 'assets/qr-code.png';
import exosIcon from 'assets/app-icon.png';
import appStore from 'assets/appstore.png';
import googlePlay from 'assets/googleplay.png';
import useMixpanel from 'hooks/useMixpanel';
import {
  BlockTypography,
  StepProps,
  Title,
  WhiteSheetWithErrorContainer,
} from './shared';

const BodyText = styled(BlockTypography)(({ theme }) => ({
  ...theme.typography.textVariant.body.s,
  strong: {
    ...theme.typography.fontFamily.reader700,
  },
  a: {
    color: theme.colors.gray['800'],
  },
}));

const LinkText = styled('span')(({ theme }) => ({
  ...theme.typography.textVariant.body.s,
  a: {
    ...theme.typography.fontFamily.sharp600,
    color: theme.colors.gray['800'],
    textDecoration: 'none',
  },
}));

const RefreshIcon = styled((props) => (
  <FontAwesomeIcon icon={faArrowRotateRight} {...props} />
))(({ theme }) => ({
  height: theme.baseUnit * 2,
  marginRight: theme.baseUnit,
}));

const Separator = styled('div')(({ theme }) => ({
  height: 1,
  backgroundColor: theme.colors.gray[900],
  marginTop: theme.baseUnit * 3,
  marginBottom: theme.baseUnit * 3,
}));

const FlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '22px',
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const FlexContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.baseUnit * 3,
  marginBottom: theme.baseUnit * 2,
}));

const FlexColumnContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const FlexBadgesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
});

const BADGE_WIDTH = '120px';

const SuccessStep: React.FC<
  { email?: string; gpid?: string } & Pick<StepProps, 'mixpanelData'>
> = ({ email, gpid, mixpanelData }) => {
  const track = useMixpanel();

  // Note: email is undefined when we don't prompt the user for it
  const title = email ? `Success!` : `Good news, you’re already verified.`;
  const location = email
    ? 'registration_new_email'
    : 'registration_existing_email';

  const trackAction = useCallback(
    ({ name, subcategory }: { name: string; subcategory?: string }) => {
      track('User Action', {
        ...mixpanelData,
        event_type: 'screen interaction',
        event_category: 'eligibility flow',
        event_location: location,
        event_name: name,
        event_subcategory: subcategory,
      });
    },
    [track, location, mixpanelData],
  );

  React.useEffect(() => {
    track('Screen View', {
      screen_name: location,
      eligible_id: gpid,
    });
  });

  return (
    <FlexContainer>
      <WhiteSheetWithErrorContainer style={{ display: 'flex' }}>
        <FlexColumnContentContainer>
          <div style={{ flex: 1 }}>
            <Title>{title}</Title>
            {email ? (
              <>
                {/* 
                  N E W L Y   V E R I F I E D 
                  For users who just went through verification for the first time.
                  We show them their email address.
                */}
                <BodyText>
                  Open your mobile device’s app store (the App Store for iPhone
                  and Google Play for Android devices), search for Exos, and
                  download.{' '}
                </BodyText>

                <BodyText>
                  Then, create your account using this email address:
                </BodyText>
                <BodyText>
                  <strong>{email}</strong>
                </BodyText>
                <BodyText>
                  Notice a typo in your email address? Contact{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://exosdigital.zendesk.com/hc/en-us/requests/new?ticket_form_id=13836393583764"
                  >
                    support@teamexos.com
                  </a>{' '}
                  for help.
                </BodyText>
              </>
            ) : (
              <>
                {/* 
                  P R E V I O U S L Y   V E R I F I E D 
                  For users who have already been through the flow OR were automatically
                  verified (ex. during bulk email import). We don't show them their email.
                */}
                <BodyText>
                  Next step –{' '}
                  <a
                    href="https://qrco.de/bdcsDz"
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => {
                      trackAction({ name: 'download_app_link_click' });
                    }}
                  >
                    click here to download the Exos app
                  </a>{' '}
                  (available on the App Store for iPhone and Google Play for
                  Android devices), and enter your email address to get started.
                </BodyText>
                <BodyText>
                  Need help? Contact{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://exosdigital.zendesk.com/hc/en-us/requests/new?ticket_form_id=13836393583764"
                  >
                    support@teamexos.com
                  </a>{' '}
                  for support.
                </BodyText>
              </>
            )}
          </div>

          {/* S A M E   F O R   B O T H */}
          <Separator />

          <BodyText>
            <strong>Want to verify your family members?</strong>
          </BodyText>

          <LinkText>
            <a
              href="/pepsico"
              onClick={() => {
                trackAction({ name: 'restart_click' });
              }}
            >
              <RefreshIcon />
              Restart
            </a>
          </LinkText>
        </FlexColumnContentContainer>
      </WhiteSheetWithErrorContainer>
      <WhiteSheetWithErrorContainer>
        <FlexContentContainer>
          <div style={{ flex: 1 }}>
            <BodyText>
              <strong>Download the Exos app to get started.</strong>
            </BodyText>
            <BodyText>
              A customized Gameplan. An empathetic Exos coach. A team to hold
              you accountable and support you.
            </BodyText>
            <BodyText>It’s all waiting in the Exos app.</BodyText>
            <BodyText>
              Download the app and unlock mind-body classes, Practices, and
              Challenges today.
            </BodyText>
            <BodyText>
              <strong>
                Exos. Designed to get you ready for the moments that matter
                most.
              </strong>
            </BodyText>
          </div>
          <div style={{ width: BADGE_WIDTH, textAlign: 'center' }}>
            <img alt="Exos Icon" src={exosIcon} style={{ maxWidth: '100%' }} />
            <img alt="QR Code" src={downloadQR} style={{ maxWidth: '100%' }} />
            <BodyText>Scan to download</BodyText>
          </div>
        </FlexContentContainer>
        <FlexBadgesContainer>
          <a
            href="https://apps.apple.com/us/app/exos-fit/id1570526785"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackAction({
                name: 'download_app_button_click',
                subcategory: 'apple',
              });
            }}
          >
            <img
              alt="App Store"
              src={appStore}
              style={{ width: BADGE_WIDTH }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.teamexos.exosfit"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackAction({
                name: 'download_app_button_click',
                subcategory: 'android',
              });
            }}
          >
            <img
              alt="Google Play"
              src={googlePlay}
              style={{ width: BADGE_WIDTH }}
            />
          </a>
        </FlexBadgesContainer>
      </WhiteSheetWithErrorContainer>
    </FlexContainer>
  );
};

export default SuccessStep;
