import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useMixpanel from 'hooks/useMixpanel';

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const track = useMixpanel();

  useEffect(() => {
    track('Screen View', {
      page_path: location.pathname,
    });
  }, [track, location]);
  return children;
};

export default Wrapper;
