import React from 'react';
import {
  OnDemandVideoPreviewFragment,
  useGetUserSavedVideosQuery,
} from 'graphql/types';
import ClassList from 'components/ClassList';
import SectionTitle from 'components/common/SectionTitle';
import { usePlaybookTheme } from 'playbook';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import useMixpanel from 'hooks/useMixpanel';

const ViewAllButton = styled('button')(({ theme }) => ({
  ...theme.typography.textVariant.button.m,
  color: theme.colors.alias.textPrimary,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  padding: 0,
}));

const SavedSection = () => {
  const theme = usePlaybookTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const track = useMixpanel();
  const { data, loading } = useGetUserSavedVideosQuery({
    variables: {
      connectionInput: {
        first: 4,
      },
    },
  });

  const shouldShowViewAll = data?.self?.savedContent.pageInfo.hasNextPage;

  const onModalOpen = (item: OnDemandVideoPreviewFragment) => {
    const position = classes.findIndex((c) => c.id === item.id);

    track('User Action', {
      event_name: 'view_on_demand_class_detail',
      event_type: 'screen interaction',
      event_category: 'on demand',
      event_subcategory: 'saved',
      event_location: 'home',
      content_id: item.id,
      section_id: 'exos:fit-saved-classes',
      position,
    });

    // since this section is used on home, we can let the Home List handle the modal opening
    // so we avoid conflicts with the modal state
    navigate(`/ondemand/${item.id}`, { state: { from: pathname } });
  };

  const onClickViewAll = () => {
    track('User Action', {
      event_category: 'on demand',
      event_location: 'home',
      event_name: 'view_all_classes',
      event_subcategory: 'saved',
      event_type: 'passive engagement',
      section_id: 'exos:fit-saved-classes',
    });
    navigate('/saved');
  };

  const classes = (data?.self?.savedContent.nodes ??
    []) as OnDemandVideoPreviewFragment[];

  if (classes.length === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: theme.baseUnit * 6 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.baseUnit,
        }}
      >
        <SectionTitle role="heading" aria-level={2}>
          Saved Classes
        </SectionTitle>
        {shouldShowViewAll && (
          <ViewAllButton onClick={onClickViewAll}>View All</ViewAllButton>
        )}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <ClassList
          classes={classes}
          loading={loading}
          pageInfo={data?.self?.savedContent.pageInfo}
          handleClick={(item) => onModalOpen(item)}
          mixpanelData={{
            event_location: 'home',
            event_subcategory: 'saved',
            section_id: 'exos:fit-saved-classes',
          }}
          wrap="nowrap"
        />
      </div>
    </div>
  );
};

export default SavedSection;
